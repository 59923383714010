@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.react-date-picker__calendar abbr {
    text-decoration: none;
    font-size: 10px;
}

.react-calendar__tile.react-calendar__month-view__days__day abbr {
    font-size: 12px;
}

.react-date-picker__calendar > .react-calendar {
    border: none;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(144, 177, 225, 0.38);
}

.react-calendar__navigation__label__labelText {
    text-transform: capitalize;
    font-size: 13px;
}

.react-calendar__tile.react-calendar__year-view__months__month {
    text-transform: capitalize;
}

.react-date-picker {
    width: 100%;
}

.react-date-picker__wrapper {
    display: block;
    width: 100%;
    padding: 0 0.85rem;
    border: 1px solid var(--color-input-border-default) !important;
    border-radius: 0.25rem;
    transition: all 200ms ease-out;
    -webkit-transition: all 200ms ease-out;
    font-size: var(--input-size);
    min-height: 37.2px;
}

.filled-date-picker-container .react-date-picker__wrapper {
    background-color: white;
}

.filled-date-picker-container.flex {
    margin-bottom: 0;
}

.filled-date-picker-container.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filled-date-picker-container.flex > div {
    flex: 1 1;
    margin-left: 2rem;
}

.filled .react-date-picker__wrapper {
    background-color: white;
}

.filled.flex {
    margin-bottom: 0;
}

.filled.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filled.flex > div {
    flex: 1 1;
    margin-left: 2rem;
}

* {
    list-style: none;
    text-decoration: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.text-color-primary {
    color: var(--color-primary);
}

/* Sidebar */
@-webkit-keyframes expandSidebar {
    from {
        width: 80px;
    }
    to {
        width: 240px;
    }
}
@keyframes expandSidebar {
    from {
        width: 80px;
    }
    to {
        width: 240px;
    }
}

@-webkit-keyframes shrinkSidebar {
    from {
        width: 240px;
    }
    to {
        width: 80px;
    }
}

@keyframes shrinkSidebar {
    from {
        width: 240px;
    }
    to {
        width: 80px;
    }
}

.sidebar-expanded {
    -webkit-animation: expandSidebar 500ms forwards;
            animation: expandSidebar 500ms forwards;
}

.sidebar-shrinked {
    -webkit-animation: shrinkSidebar 500ms forwards;
            animation: shrinkSidebar 500ms forwards;
}

/* Sidebar menu labels */
@-webkit-keyframes hideLabel {
    to {
        width: 0;
        height: 0;
    }
}
@keyframes hideLabel {
    to {
        width: 0;
        height: 0;
    }
}

.label-hidden {
    -webkit-animation: hideLabel 150ms forwards;
            animation: hideLabel 150ms forwards;
    -webkit-animation-delay: 200ms;
            animation-delay: 200ms;
}

/* Main area */
@-webkit-keyframes expandMain {
    from {
        margin-left: 240px;
    }
    to {
        margin-left: 80px;
    }
}
@keyframes expandMain {
    from {
        margin-left: 240px;
    }
    to {
        margin-left: 80px;
    }
}

@-webkit-keyframes shrinkMain {
    from {
        margin-left: 80px;
    }
    to {
        margin-left: 240px;
    }
}

@keyframes shrinkMain {
    from {
        margin-left: 80px;
    }
    to {
        margin-left: 240px;
    }
}

.main-expanded {
    -webkit-animation: shrinkMain 500ms forwards;
            animation: shrinkMain 500ms forwards;
}

.main-shrinked {
    -webkit-animation: expandMain 500ms forwards;
            animation: expandMain 500ms forwards;
}

/* Toggle button */
@-webkit-keyframes slideExpand {
    from {
        left: 240px;
    }
    to {
        left: 80px;
    }
}
@keyframes slideExpand {
    from {
        left: 240px;
    }
    to {
        left: 80px;
    }
}

@-webkit-keyframes slideShrink {
    from {
        left: 80px;
    }
    to {
        left: 240px;
    }
}

@keyframes slideShrink {
    from {
        left: 80px;
    }
    to {
        left: 240px;
    }
}

.toggler-expanded {
    -webkit-animation: slideShrink 500ms forwards;
            animation: slideShrink 500ms forwards;
}

.toggler-shrinked {
    -webkit-animation: slideExpand 500ms forwards;
            animation: slideExpand 500ms forwards;
}

/* Quotation Tables */
.operations > span:not(:first-child) {
    margin-top: 0.25rem;
}

.flex-card {
    display: flex;
    flex-direction: column;
}

.dossier-cee-name {
    color: #90b1e1 !important;
    font-weight: 600 !important;
    font-size: 15px !important;
}

.button-product-catalogue .material-icons {
    margin-right: 0!important;
}
